import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import moment from 'moment';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { getTeamInfo, getTeamMembers } from 'redux/actions/teams';
import { gray5 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { Button } from 'react-bootstrap';
import TeamMembers from './team-members';
import prodPathReplace from 'shared/prod-path-rewrite';
import { isHandheld } from 'styles/global_defaults/media-queries';

const styles = css`
  .divider {
    height: 1px;
    background-color: ${gray5};
    margin-top: $half-spacing;
    margin-bottom: $half-spacing;
    width: 100%;
  }
  img {
    height: ${standardSpacing}px;
    width: ${standardSpacing}px;
  }
`;

const ViewTeamsMeeting = (props: { meeting: any }) => {
  const { $state } = useContext(AngularServicesContext);
  const [isActive, setIsActive] = useState(false);

  const { meetingTime, agenda, sessions, userId } = props?.meeting;
  const currentTime = moment();
  const startTime = moment(meetingTime);
  const endTime = moment(sessions[0]?.endTime);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchTeamInfo() {
      await dispatch(getTeamInfo({
        catalogId: $state.params.catalogId,
        teamId: $state.params.teamId,
      }));
      dispatch(getTeamMembers({
        catalogId: $state.params.catalogId,
        teamId: $state.params.teamId,
      }));
    }
    fetchTeamInfo();
  }, [$state.params.catalogId, $state.params.teamId, dispatch]);

  useEffect(() => {
    const checkTime = () => {
      if (currentTime.isSameOrAfter(startTime.subtract(10, 'minute'))
        && currentTime.isSameOrBefore(endTime)) {
        setIsActive(true);
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 1000);

    return () => clearInterval(interval);
  }, [meetingTime]);

  const joinEvent = () => {
    window.location = sessions[0].joinUrl
  }

  return (
    <div css={styles}>
      <TeamMembers creatorId={userId} />
      {props.meeting && (
        <React.Fragment>
          <label className='label text-gray-2 mt-6'>
            {t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.VIEW.DESCRIPTION()}
          </label>
          <div>{agenda}</div>
          <div className='divider' />
          <label className='label text-gray-2'>
            {t.TEAMS.WORKSPACE.MEETING.LOCATION()}
          </label>
          <div className='d-flex'>
            <img
              src={prodPathReplace('images/msteam-blue.png')}
              alt='Microsoft Teams Meeting'
            />
            <div className='ml-1'>
              {t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.LOCATION()}
            </div>
          </div>
          <div className='divider' />
          <div className={`d-flex ${isHandheld ? 'flex-column': ''}`}>
            <div className={`d-flex flex-column ${isHandheld ? 'w-100': 'w-50 pr-4'}`}>
              <label className='label text-gray-2'>
                {t.TEAMS.WORKSPACE.MEETING.DATE()}
              </label>
              <div>{moment(meetingTime).format('ll')}</div>
              <div className='divider' />
            </div>
            <div className={`d-flex flex-column ${isHandheld ? 'w-100': 'w-50'}`}>
              <label className='label text-gray-2'>
                {t.TEAMS.WORKSPACE.MEETING.TIME()}
              </label>
              <div>{moment(meetingTime).format('LT')}</div>
              <div className='divider' />
            </div>
          </div>
          <div className='button-bar my-4'>
            <Button
              className='primary'
              disabled={!isActive}
              onClick={joinEvent}
            >
              {currentTime.isSameOrBefore(endTime)
                ? t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.VIEW.JOIN()
                : t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.VIEW.CONCLUDED()
              }
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ViewTeamsMeeting;
